// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

global.Rails = require('@rails/ujs');
Rails.start();

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import $ from 'jquery';
global.$ = jQuery;

import '../stylesheets/application'

require('bootstrap')
import "@fortawesome/fontawesome-free/js/all";

import './fingerprint'
require('./activation.js')
require('./products.js')
require('./intents.js')
require('./iframe.js')
require('./google_analytics.js')
require('./ping.js')
require('./contact_form.js')
require('./sampling_checkout.js')

//Rescue from CurrentCartError 
//- will return 403 status code with a redirect_path
document.addEventListener('turbolinks:load', function(event) {
  $.ajaxSetup({
    statusCode: {
      403: function(data) {        
        Turbolinks.visit(data.redirect_path)
      }
    }
  });
});


//document.addEventListener('turbolinks:load', function(event) {});
  
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
