var interval = null
var hidden = "hidden";

function cartActive() {
  return ($("#cart-active").length > 0)
}

function orderActive() {
  return ($("#order-active").length > 0)
}

function handleDisconnect() {
  clearTimer();
  if ( cartActive() ) {
    $(".connection-status").removeClass("warning")
    $("#modal-active-cart").modal("show");
  }
}

function handleWarningDisconnect(counter) {
  if ( cartActive() ) {
    $(".connection-status #message").html("Vengo connection lost, retrying for another " + counter + " seconds");
    $(".connection-status").addClass("warning");
  }
}

function handleRestore() {
  if ( cartActive() ) {
    if ( $(".connection-status.warning").length > 0 ) {
      $(".connection-status #message").html("Vengo connection has been restored");
      $(".connection-status").removeClass("warning").addClass("restored")
      setTimeout(function(){
        $(".connection-status").removeClass('restored');
      }, 3000);
    }
  }
}

function sendActive() {
  Rails.ajax({
    type: "POST",
    url: "/user/cart/pings.json",
    data: {},
    success: function(data) {
      if (data.counter <= 0) {
        handleDisconnect();
      }
      else if (data.counter <= 12) {
        handleWarningDisconnect(data.counter);
      }
      else {
        handleRestore();
      }
    },
    error: function(data) {
      handleDisconnect();
    }
  });
}

function startTimer() {
  var timeout = 1000;
  interval = setInterval(function(){
    sendActive();
  }, timeout);
}

function clearTimer() {
  clearInterval(interval);
  interval = null  
}

function onLoad() {
  if ( !cartActive() && !orderActive() ) {
    clearTimer();
    return
  }
  if (!interval) {
    startTimer();
  }
}

function onchange (evt) {
  var v = 'visible';
  var h = 'hidden';
  var evtMap = { focus:v, focusin:v, pageshow:v, blur:h, focusout:h, pagehide:h };

  evt = evt || window.event;
  if (evt.type in evtMap) {
    if (evtMap[evt.type] == "visible") {
      onLoad();
    }
    else {
      clearTimer();
    }
  }
  else {
    if (this[hidden]) {
      clearTimer();
    }
    else {
      onLoad();
    }
  }
}

$(document).on("show.bs.modal", "#modal-active-cart", function(event){
  clearTimer();
})

$(document).on("hide.bs.modal", "#modal-active-cart", function(event){
  Turbolinks.visit("/");
})

document.addEventListener('turbolinks:load', function(event) {
  onLoad();

  if (hidden in document) {
    document.addEventListener("visibilitychange", onchange);
  }
  else if ((hidden = "mozHidden") in document){
    document.addEventListener("mozvisibilitychange", onchange);
  }
  else if ((hidden = "webkitHidden") in document) {
    document.addEventListener("webkitvisibilitychange", onchange);
  }
  else if ((hidden = "msHidden") in document) {
    document.addEventListener("msvisibilitychange", onchange);
  }
  // IE 9 and lower:
  else if ('onfocusin' in document) {
    document.onfocusin = document.onfocusout = onchange;
  }
  // All others:
  else {
    window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange;
  }
});