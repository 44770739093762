var processingTransactionMessage = "<div>Uploading your responses</div><div class='fs-7'>Do not leave this page</div>";
var uploadingTransactionMessage = "<div>Sending your order to the Vengo</div><div class='fs-7'>Please retrieve your item from the tray,<br /> once it is dispensed</div>";
var connectionErrorMessage = "<div>Vengo connection not found</div><div>Please try again or reconnect your mobile cart</div>";
var processingErrorMessage = "<div>Sorry, there was an error uploading your response</div><div>Please try again</div>";
var cartId;
var pingRetries;

function bindEvent(element, eventName, eventHandler) {
  if (element.addEventListener){
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent('on' + eventName, eventHandler);
  }
} 

function showProcessingScreen(message) {
  $("body").addClass("loading");   
  $(".ajax-loading-modal #message").html(message);
}

function hideProcessingScreen() {
  $(".ajax-loading-modal #message").html("");
  $("body").removeClass("loading");
}

function showErrorModal(error) {
  $("#modal-checkout-errors #modal-body").html(error);
  $("#modal-checkout-errors").modal("show"); 
}

function exitPromotion() {
  $.ajax({
    url: '/user/cart/sampling_checkout',
    type: 'DELETE'
  });
}

function updateProfile(profile) {
  $.ajax({
    type: "PATCH",
    url: "/user/profile",
    data: { user_profile: profile },
    dataType: "json",
    success: function(data) {
    },
    error: function(data) {
    },
  });
}

function ping() {
  return $.ajax({
    type: "POST",
    url: "/user/cart/pings.json",
    data: {},
    beforeSend: function(){
      showProcessingScreen(processingTransactionMessage);
    }
  });
}

function redeem(data) {
  return $.ajax({
    type: "POST",
    url: "/user/cart/promotion/redemption",
    data: JSON.stringify({ "redemption" : data }),
    dataType: "json",
    contentType: 'application/json',
    beforeSend: function(){
      showProcessingScreen(processingTransactionMessage)
    }
  });
}

function checkout() {
  return $.ajax( {
    type: "POST",
    url: "/user/orders/" + cartId + "/upload",
    beforeSend: function(){
      showProcessingScreen(uploadingTransactionMessage);
    }
  });
}

function redeemAndCheckout(redemptionData) {
  ping().then(function(result, textStatus, jqXHR) {
    if (result.counter < 15) {
      return $.Deferred().reject({ "responseJSON" : {"error" : connectionErrorMessage} });
    }
    pingRetries = 0;
    return redeem(redemptionData);
  }).then(function(data, textStatus, jqXHR) {
    return checkout();
  }).done(function(response) {
  }).fail(function(response, status, err) {
    pingRetries -= 1;
    if (pingRetries >= 0  ) {
      setTimeout(function(){ redeemAndCheckout(redemptionData); }, (500 * pingRetries));
    }
    else {
      showErrorModal(response.responseJSON.error);
    }
  });
}

function loadData() {
  var iframe = document.getElementById('sampling-iframe');
  $.ajax({
    type: "GET",
    url: "/user/cart/sampling_checkout",
    dataType: "json",
    contentType: 'application/json',
    success: function(data) {
      cartId = data.cart_id        
      iframe.contentWindow.postMessage( JSON.stringify(data), '*');
    }
  });
}

document.addEventListener("turbolinks:load", () => {
  if ($("#sampling-iframe").length == 0) {
    return
  }
    
  // Listen to message from child window
  bindEvent(window, 'message', function (e) {
    try {
      obj = JSON.parse(e.data);
      if (obj.command == "exit") {
        exitPromotion();
      }
      else if (obj.command == "reload") {
        loadData();
      }
      else if (obj.command == "submit") {
        pingRetries = 3;
        updateProfile(obj.data.profile);
        redeemAndCheckout(obj.data);
      }
    } 
    catch (err) {
    }
  });
    
  $('#sampling-iframe').on("load", loadData);
});