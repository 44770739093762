document.addEventListener('turbolinks:load', function(event) {
  if ($("body[data-page='SamplingCheckoutsNew']").length == 0) {
    return;
  }
  if ($("#sampling-iframe").length == 0) {
    //$("#modal-promotion-errors").find("#modal-body").html("Promotion is not available at this time.");
    $("#modal-promotion-errors").modal("show");
  } 
});

$(document).on( 'hide.bs.modal', "body[data-page='SamplingCheckoutsNew']:not(#sampling-iframe) #modal-promotion-errors", function (e) {
  $.ajax({
    url: '/user/cart/sampling_checkout',
    type: 'DELETE'
  });
})