var processingTransactionMessage = "<div>Processing your transaction</div><div class='fs-7'>Do not leave this page</div>";
var uploadingTransactionMessage = "<div>Sending your order to the Vengo</div><div class='fs-7'>Please retrieve your item from the tray,<br /> once it is dispensed</div>";
var connectionErrorMessage = "<div>Vengo connection not found</div><div>Please try again or reconnect your mobile cart</div>";
var processingErrorMessage = "<div>Sorry, there was an error processing your payment</div><div>Please try again or use a different payment method</div>";

var stripe = Stripe($('meta[name="stripe-key"]').attr('content'));
var elements = stripe.elements();
var cartId;
var pingRetries;

function checkResponse(response) {
  if (response.status >= 200 && response.status <= 299) {
    return response.json();
  } else {
    throw Error(response.error);
  }
}

function loadManualHeader() {
  $("#payment-manual-message #toggle-or").removeClass("d-none");
  $("#payment-manual-message #message").addClass("text-lowercase");
}

function showProcessingScreen(message) {
  $("body").addClass("loading");   
  $(".ajax-loading-modal").find("#message").html(message);
}

function hideProcessingScreen() {
  $(".ajax-loading-modal").find("#message").html("");
  $("body").removeClass("loading");
}

function showErrorModal(error) {
  $("#modal-checkout-errors").find("#modal-body").html(error);
  $("#modal-checkout-errors").modal("show");
}

function canSubmit() {
  var requiredEmpty = $(".form-control.required.is-empty").length
  var isInvalid = $(".form-control.is-invalid").length;
  var invalid = $(".form-control:invalid").length
  return (requiredEmpty + isInvalid + invalid) == 0
}

function ping() {
  return $.ajax({
    type: "POST",
    url: "/user/cart/pings.json",
    data: {},
    beforeSend: function(){
      showProcessingScreen(processingTransactionMessage);
    }
  });
}

function initializePaymentRequest(cartData) {
  cartId = cartData.id
  
  var prButton = elements.getElement('paymentRequestButton');

  paymentRequest = stripe.paymentRequest({
      country: cartData.country,
      currency: cartData.currency,
      total: {
        label: "Vengo Vending Machine",
        amount: cartData.total_price_in_cents,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

  //ApplePay&GooglePay
  if (!prButton) {
    prButton = elements.create('paymentRequestButton', {
      paymentRequest: paymentRequest,
      style: {
        paymentRequestButton: {
          type: 'buy', 
          theme: 'dark',
          height: '44px'
        }
      }
    });
  } 

  paymentRequest.canMakePayment().then(function(result) {
    //Show Payment Requiest button if results
    if (result) {
      prButton.mount('#payment-request-button');
      $("#payment-request-button-wrapper").removeClass("d-none");
      loadManualHeader();
    }
  });

  paymentRequest.on('paymentmethod', function(ev) {
    pingRetries = 3;
    var paymentObject = {payment_method: ev.paymentMethod.id}
    prProcessAndCheckout(ev, paymentObject);
  });
}

function getCheckout() {
  return fetch('/user/cart/checkout.json');
}

function confirmCardPayment(paymentObject, clientSecret) {
  return stripe.confirmCardPayment(
    clientSecret,
    paymentObject
  )
}

function confirmCardPaymentHandler(result) {
  if (result.error) {
    return $.Deferred().reject({ "responseJSON" : {"error" : processingErrorMessage} });
  }
  return $.ajax( {
    type: "POST",
    url: "/user/orders/" + cartId + "/upload",
    beforeSend: function(){
      showProcessingScreen(uploadingTransactionMessage);
    }
  });
}

function prProcessAndCheckout(event, paymentObject) {
  var ev = event;
  var clientSecret;
  ping().then(function(result, textStatus, jqXHR) {
    if (result.counter < 15) {
      return $.Deferred().reject({ "responseJSON" : {"error" : connectionErrorMessage} });
    }
    pingRetries = 0;
    return getCheckout();
  }).then(function(response) {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    }
    else {
      return $.Deferred().reject({ "responseJSON" : {"error" : processingErrorMessage} });
    }
  }).then(function(responseJson) {
    clientSecret = responseJson.client_secret
    showProcessingScreen(processingTransactionMessage);
    return confirmCardPayment(paymentObject, clientSecret);
  }).then(function(confirmResult) {
    if (confirmResult.error) {
      return $.Deferred().reject({ "responseJSON" : {"error" : processingErrorMessage} });
    }
    else {
      ev.complete('success');
      return stripe.confirmCardPayment(clientSecret)
    }
  }).then(function(result) {
    return confirmCardPaymentHandler(result)
  }).done(function(response) {
  }).fail(function(response, status, err) {
    pingRetries -= 1;
    if (pingRetries >= 0  ) {
      setTimeout(function(){ prProcessAndCheckout(ev,paymentObject ) }, (500 * pingRetries));
    }
    else {
      $("input[type=submit]").each(function() { Rails.enableElement(this) })
      ev.complete('fail');
      showErrorModal(response.responseJSON.error);
    }
  });
}

function elementProcessAndCheckout(paymentObject) {
  
  ping().then(function(result, textStatus, jqXHR) {
    if (result.counter < 15) {
      return $.Deferred().reject({ "responseJSON" : {"error" : connectionErrorMessage} });
    }
    pingRetries = 0;
    return getCheckout();
  }).then(function(response) {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    } else {
      return $.Deferred().reject({ "responseJSON" : {"error" : processingErrorMessage} });
    }    
  }).then(function(responseJson) {
    showProcessingScreen(processingTransactionMessage);
    return confirmCardPayment(paymentObject, responseJson.client_secret);
  }).then(function(result) {
    return confirmCardPaymentHandler(result)
  }).done(function(response) {
  }).fail(function(response, status, err) {
    pingRetries -= 1;
    if (pingRetries >= 0  ) {
      setTimeout(function(){ elementProcessAndCheckout(paymentObject) }, (500 * pingRetries));
    }
    else {
      $("input[type=submit]").each(function() { Rails.enableElement(this) })
      showErrorModal(response.responseJSON.error);
    }
  });
}

document.addEventListener("turbolinks:load", () => {
  if ($("#payment-request-button").length == 0 ) {
    return;
  }
  fetch('/user/cart.json')
    .then(response => response.json())
    .then(data => initializePaymentRequest(data))
});

document.addEventListener("turbolinks:load", () => {
  if ($("#checkout_card_number").length == 0 ) {
    return;
  }

  var style = {
    base: {
      fontFamily: 'Open Sans, "Helvetica Neue", Helvetica, Arial, sans-serif',
      color: "#545558",
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0.5px',
      '::placeholder': {
        fontFamily: 'Open Sans, "Helvetica Neue", Helvetica, Arial, sans-serif',
        color: "#545558",
        fontWeight: 300,
        fontSize: '16px',
        lineHeight: '19px'
      }
    }
  };
  var classes = {
    invalid: "is-invalid",
    empty: 'is-empty',
    complete: "is-valid"
  };
  
  var cardNumber = elements.getElement('cardNumber');
  var cardExpiry = elements.getElement('cardExpiry');
  var cardCvc = elements.getElement('cardCvc');

  //Manual card entry
  //var cardNumber;
  if (!cardNumber) {
    cardNumber = elements.create('cardNumber', {
      style: style,
      classes: classes,
      placeholder: 'Credit Card Number'
    });
  }
    
  if (!cardExpiry) {
    cardExpiry = elements.create('cardExpiry', {
      style: style,
      classes: classes,
      placeholder: 'MM / YY'
    });
  }
  
  if (!cardCvc) {
    cardCvc = elements.create('cardCvc', {
      style: style,
      classes: classes,
      placeholder: 'CVV'
    });
  }

  cardNumber.mount('#checkout_card_number');
  cardNumber.on('change', function(event) {
    if (event.error) {
      $("form").addClass("was-validated");
      $("#card-number-wrapper").find(".invalid-feedback").html("Enter valid credit card number");
    }
  });
  
  cardExpiry.mount('#checkout_card_expiry');
  cardExpiry.on('change', function(event) {
    if (event.error) {
      $("form").addClass("was-validated");
      $("#card-expiry-wrapper").find(".invalid-feedback").html("Enter valid expiration date");
    }
  });
  
  cardCvc.mount('#checkout_card_cvv');
  cardCvc.on('change', function(event) {
    if (event.error) {
      $("form").addClass("was-validated");
      $("#card-cvv-wrapper").find(".invalid-feedback").html("Enter valid security code");
    }
  });
  
  //Submit Card Pay
  $("#checkout-form").on("submit", function(event){
    event.preventDefault();

    $("input[type=submit]").each(function() { Rails.disableElement(this) });

    if (!canSubmit()) {
      $("form").addClass("was-validated");
      setTimeout(function() { 
          $("input[type=submit]").each(function() { Rails.enableElement(this) });
        }, 
        500
      );
      return
    }
    
    pingRetries = 3;
    var paymentObject = {
      payment_method: {
        card: cardNumber,
        billing_details: {
          name: $("#checkout_name").val(),
          email: $("#checkout_email_address").val()
        }
      },
      setup_future_usage: 'on_session'
    }
    elementProcessAndCheckout(paymentObject);
  });
  
});

document.addEventListener("turbolinks:load", () => {
  $("#checkout-form input").on("invalid", function(event) {
    event.preventDefault();
    $("form").addClass("was-validated");
    $(event.target).siblings(".invalid-feedback").html($(event.target).data("validationMessage"))
  });
});

$(document).on("blur", "#checkout-form input", function(event) {
  $(this)[0].checkValidity();
});

$(document).on("hide.bs.modal", "#modal-checkout-errors", function(event) {
  hideProcessingScreen();
})
