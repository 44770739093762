import Fingerprint2 from 'fingerprintjs2';

function uploadFingerprint(data) {
  $.ajax({
    type: "PATCH",
    url: "/user/profile",
    data: data,
    dataType: "json",
    success: function(data) {
      $("#div-device-id").attr("data-id", data.device_id);
    }
  });
}

document.addEventListener("turbolinks:load", () => {
  var previousId = $("#div-device-id").data("id");
  
  if (window.requestIdleCallback) {
      requestIdleCallback(function () {
          Fingerprint2.get(function (components) {
              var values = components.map(function (component) { return component.value });
              var murmur = Fingerprint2.x64hash128(values.join(''), 31);
              if (murmur != previousId) {
                uploadFingerprint({ "user_profile" : { "device_id" : murmur} });
              } 
          })
      })
  } else {
      setTimeout(function () {
          Fingerprint2.get(function (components) {
            var values = components.map(function (component) { return component.value });
            var murmur = Fingerprint2.x64hash128(values.join(''), 31);
            if (murmur != previousId) {
              uploadFingerprint({ "user_profile" : { "device_id" : murmur} });
            } 
          })  
      }, 1000)
  }
});