import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);
import 'swiper/swiper-bundle.css';

//Card Support
document.addEventListener("turbolinks:load", () => {
  if ( $(".swiper-container").length == 0 ) { return }
  var swiper = new Swiper('.swiper-container', {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
  });
});